


































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchGetDistritos,
  dispatchUpdateDistrito,
} from '@/store/distrito/actions';
import { consultarDistrito } from '@/store/distrito/getters';
import { IDistritoUpdate } from '@/interfaces/distrito';

@Component
export default class AlterarDistrito extends Vue {
  public valid = true;
  public nome: string = '';

  public async mounted() {
    await dispatchGetDistritos(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.distrito) {
      this.nome = this.distrito.nome;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const distritoAtualizado: IDistritoUpdate = {
        nome: this.nome,
      };

      const atualizou = await dispatchUpdateDistrito(this.$store, {
        id: this.distrito!.id,
        distrito: distritoAtualizado,
      });
      if (atualizou) {
        this.$router.push('/main/distritos/listar');
      }
    }
  }

  get distrito() {
    return consultarDistrito(this.$store)(+this.$router.currentRoute.params.id);
  }
}
